<template>
  <div>

  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },
  props: {
    filter: {
      required: true,
      type: Object,
    },

    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      
    };
  },
  watch: {
    items(val) {
      return (this.items = val);
    },
  },
  methods: {
    handleChangeTake(value) {
      this.$emit("handleChangeTake", value);
    },
    pagination(page) {
      this.$emit("pagination", page);
    },

    setNewCodeData() {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
}
.color-red {
  color: #dc3545;
}
.color-green {
  color: #28a745;
}
.color-blue {
  color: lightcoral;
}
</style>
