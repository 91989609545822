<template>
  <b-modal v-model="modalAdminList" size="xl">
    <template #modal-header>
      <div class="title-tabs">Select Admin</div>
    </template>

    <div class="no-gutters bg-white">
      <b-input-group class="mb-3">
        <b-form-input
          class="search-bar"
          placeholder="Search Admin Name, Email"
          v-model="filter.search"
          @keypress.enter="handleSearch"
        >
        </b-form-input>
        <b-input-group-append is-text>
          <b-iconstack font-scale="2" type="submit" @click="handleSearch">
            <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
          </b-iconstack>
        </b-input-group-append>
      </b-input-group>

      <b-row>
        <b-col>
          <b-table
            fixed
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
            id="table-show-Admin"
          >
            <template v-slot:cell(id)="data">
              <b-form-group class="mb-0">
                <b-form-checkbox-group v-model="adminSelectList">
                  <b-form-checkbox
                    :key="data.item.user_guid"
                    :value="data.item.user_guid"
                    @change="addAdminList(data.item)"
                  >
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </template>
            <template v-slot:cell(active)="{ item }">
              <span :class="item.active ? 'text-success' : 'text-error'">{{
                item
              }}</span>
            </template>
            <template v-slot:cell(team)="{ item }">
              {{ item.team || "-" }}
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <Pagination
        :filter="filter"
        :rows="rows"
        take="pageSize"
        @pagination="pagination"
        @handleChangeTake="handleChangeTake"
      />
    </div>
    <template #modal-footer>
      <b-row class="content-between w-100">
        <b-col class="text-left" cols="2">
          <b-button @click.prevent="hide(1)" class="border-btn" block
            >Cancel</b-button
          >
        </b-col>

        <b-col cols="2">
          <b-button class="submit-btn" @click.prevent="submit" block
            >Save</b-button
          >
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import TableAdminList from "./TableAdminList";
export default {
  components: { TableAdminList },
  data() {
    return {
      modalAdminList: false,
      filter: {
        search: "",
        page: 1,
        pageSize: 10,
      },
      isBusy: false,
      items: [],
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      fields: [
        {
          key: "id",
          label: "",
          class: "w-1",
        },
        {
          key: "name",
          label: "Admin Name",
          class: "w-3",
        },
        {
          key: "email",
          label: "Email",
          class: "w-2",
        },

        {
          key: "role",
          label: "Role",
          class: "w-2",
        },
        {
          key: "team",
          label: "Team",
          class: "w-2",
        },
      ],
      tempSelect: [],
      rows: 0,
      adminSelectList: [],
      adminList: [],
    };
  },
  mounted() {},
  watch: {},
  methods: {
    getAdminList() {
      this.isBusy = true;
      this.axios.post(`/team/filter/admin`, this.filter).then((res) => {
        if (res.data.result) {
          this.isBusy = false;
          this.items = res.data.detail.data;
          this.rows = res.data.detail.total;
        }
      });
    },
    addAdminList(val) {
      let check = this.adminList.findIndex(
        (el) => el.user_guid == val.user_guid
      );
      if (check == -1) this.adminList.push(val);
      else this.adminList.splice(check, 1);
    },
    pagination(page) {
      this.filter.page = page;
      this.getAdminList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.pageSize = value;
      this.getAdminList();
    },
    handleSearch() {
      this.filter.page = 1;
      this.getAdminList();
    },
    hide() {
      this.filter = {
        search: "",
        page: 1,
        pageSize: 10,
      };
      this.modalAdminList = false;
    },
    show(val) {
      this.adminList = val ? [...val] : [];
      this.adminSelectList = val ? val.map((el) => el.user_guid) : [];
      this.getAdminList();
      this.modalAdminList = true;
    },

    submit(val) {
      this.$emit("submit", this.adminList);
      this.hide();
    },
  },
};
</script>
<style scoped>
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
::v-deep .modal-header {
  display: block !important;
  padding: unset;
}
::v-deep .w-20px {
  width: 20px;
}
</style>
